import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { trace } from '@opentelemetry/api';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { trace, context } from '@opentelemetry/api';

// Configure the Honeycomb exporter
const exporter = new OTLPTraceExporter({
    url: 'https://api.honeycomb.io:443/v1/traces',
    headers: {
        'x-honeycomb-team': process.env.HONEYCOMB_API_KEY,
        'x-honeycomb-dataset': 'pos-frontend-webapp',
    },
});

const exporterSelfHosted = new OTLPTraceExporter({
    url: process.env.OTEL_COLLECTOR_URL,
});

function getCurrentTraceContext(currentSpan) {
    if (currentSpan && currentSpan.spanContext) {
        const spanContext = currentSpan.spanContext();
        const traceId = spanContext.traceId;
        const spanId = spanContext.spanId;
        const traceparent = `00-${traceId}-${spanId}-01`;

        return { traceId, spanId, traceparent };
    } else {
        return null;
    }
}

export const track = (eventName, attrs) => {
    window && window.dxTelemetry && window.dxTelemetry.trackEvent && window.dxTelemetry.trackEvent(eventName, attrs);
};

export const getTracking = (settings) => {
    const provider = new WebTracerProvider({
        resource: new Resource({
            [SemanticResourceAttributes.SERVICE_NAME]: 'pos-browser-' + process.env.VERCEL_ENV,
            [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: process.env.VERCEL_ENV,
            [SemanticResourceAttributes.SERVICE_VERSION]: process.env.VERCEL_GIT_COMMIT_SHA,
            'git.commit-message': process.env.VERCEL_GIT_COMMIT_MESSAGE,
            'git.branch': process.env.VERCEL_GIT_COMMIT_REF,
            'fiveten.tenant.id': settings.hash,
            'fiveten.saleunit.id': settings.saleUnit,
            'fiveten.saleunit.deviceid': settings.device,
        }),
    });

    if (process.env.VERCEL_ENV !== 'development') {
        provider.addSpanProcessor(new BatchSpanProcessor(exporter));
    }
    provider.addSpanProcessor(new BatchSpanProcessor(exporterSelfHosted));
    provider.register();

    // Register any automatic instrumentation plugins you need
    registerInstrumentations({
        instrumentations: [
            getWebAutoInstrumentations(),
            new UserInteractionInstrumentation({
                eventNames: ['keypress', 'submit', 'click'],
            }),
        ],
    });

    const tracer = trace.getTracer();

    const traceContext = getCurrentTraceContext();

    window.dxTelemetry = {};

    return {
        trackEvent: (window.dxTelemetry.trackEvent = (eventName, eventProperties) => {
            const tracer = trace.getTracer();
            tracer.startActiveSpan('track-event', (span) => {
                span.setAttribute('event.name', eventName);

                const setNestedAttribute = (name, value) => {
                    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                        Object.keys(value).forEach((key) => {
                            const newName = name === null ? key : name + '.' + key;

                            if (typeof value[key] === 'object' && !Array.isArray(value[key]) && value[key] !== null) {
                                setNestedAttribute(newName, value[key]);
                            } else {
                                span.setAttribute(newName, value[key]);
                            }
                        });
                    } else {
                        span.setAttribute(name, value);
                    }
                };

                // You can add any JSON object as attributes
                if (eventProperties) {
                    setNestedAttribute(null, eventProperties);
                }
                // End the span
                span.end();
            });
        }),
        traceContext: traceContext,
    };
};
