import '@webcomponents/custom-elements';
import { register } from '../utils/webcomponents';
import { track } from '../../tracking';

export class SeatsIOSeatChart extends HTMLElement {
    private chart: any;
    eventId: any;
    workspaceId: any;
    preSelection: number;
    maxSelection: number;
    holdToken: string;
    constructor() {
        super();
    }

    connectedCallback() {
        this.render();
    }

    static get observedAttributes() {
        return ['data-clear-selection'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name == 'data-clear-selection' && newValue === 'true' && this.chart) {
            this.chart.clearSelection();
        }
    }

    render() {
        this.chart = new window.seatsio.SeatingChart({
            container: this,
            workspaceKey: this.workspaceId,
            holdToken: this.holdToken,
            event: this.eventId,
            objectPopover: {
                confirmSelection: 'never',
            },
            fitTo: 'widthAndHeight',
            session: this.holdToken ? 'manual' : 'start',
            showFullScreenButton: false,
            showSeatLabels: true,
            selectBestAvailable: {
                number: this.preSelection,
            },
            maxSelectedObjects: this.maxSelection || null,
            onObjectSelected: () => {
                track('seatChart.onObjectSelected', {
                    selectedSeats: this.chart.selectedSeats,
                    eventId: this.eventId,
                    holdToken: this.holdToken,
                });
                const evt = new CustomEvent('seats-selection-changed', {
                    bubbles: false,
                    detail: { seats: this.chart.selectedSeats },
                });
                this.dispatchEvent(evt);
            },
            onObjectDeselected: () => {
                const evt = new CustomEvent('seats-selection-changed', {
                    bubbles: false,
                    detail: { seats: this.chart.selectedSeats },
                });
                this.dispatchEvent(evt);
            },
            onChartRendered: (chart: { holdToken: any }) => {
                track('seatChart.onChartRendered', {
                    selectedSeats: this.chart.selectedSeats,
                    eventId: this.eventId,
                    holdToken: this.holdToken,
                });
                const evt = new CustomEvent('chart-rendered', {
                    bubbles: false,
                    detail: { holdToken: chart.holdToken },
                });
                this.dispatchEvent(evt);
            },
        }).render();
    }
}

register('seatsio-seatchart', SeatsIOSeatChart);
